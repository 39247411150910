import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../api/axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setSiteLoading } from "../../stores/siteSlice";
import PasswordInput from "../../components/password-input/PasswordInput";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  let { action } = useParams();
  const navigate = useNavigate();
  // const id = atob(action).split(",");
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(setSiteLoading(true))
    const object = { ...data, hash:action };
    axiosInstance
      .post(`${process.env.REACT_APP_BASE_URL}/auth/PasswordUpdate`, object)
      .then((result) => {
        Swal.fire("Başarılı", result.data.message, "success").then((result) => {
          navigate("/login");
        });
      })
      .catch((error) => {
        Swal.fire("Hata!", error.response.data.message, "error");
      }).finally(() => {
        dispatch(setSiteLoading(false))
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-container">
        <PasswordInput register={register} placeholder={'Yeni Şifre Giriniz'} name="newPassword" errors={errors} showPassword={showPassword} setShowPassword={setShowPassword} />
        <PasswordInput customRegisterProps={{
          validate: (value) => {
            const { newPassword } = getValues();
            return newPassword === value || `Şifreler Uyuşmuyor!`;
          },
        }} showPassword={showNewPassword} setShowPassword={setShowNewPassword} placeholder={"Yeni Şifre Tekrar Giriniz"} register={register} name="newPassword2" errors={errors} /> 
       <button type="submit" className="btn-login">
          Gönder
        </button>
      </div>
    </form>
  );
};

export default ResetPassword;
