import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
} from "@mui/material";
import {
  AccountCircle,
  MailOutlined,
  Numbers,
  VpnKey,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import styles from "./create-user.module.css";
import { useState } from "react";
import { axiosInstance } from "../../api/axios";
import { useDispatch } from "react-redux";
import { setSiteLoading } from "../../stores/siteSlice";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";

const CreateUser = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [autoRenew, setAutoRenew] = useState(false);

  const onSubmit = (data) => {
    const phoneControlTR = data.phone.slice(2);
    if (phoneControlTR.length < 10) {
      Swal.fire(
        "Uyarı!",
        "Lütfen geçerli bir telefon numarası girin.",
        "warning"
      );
      return; // İşlemi sonlandır
    } else {
      data.seansNumber = +data.seansNumber;
      if (data.auto_renew == 0) {
        data.auto_renew_day = null;
      }
      dispatch(setSiteLoading(true));

      axiosInstance
        .post("/participant", data)
        .then((res) => {
          Swal.fire("Başarılı!", "Kullanıcı başarıyla oluşturuldu.", "success");
          navigate("/users");
        })
        .catch((error) => {
          Swal.fire("Hata!", error.response.data.message, "error");
        })
        .finally(() => {
          dispatch(setSiteLoading(false));
        });
    }
  };

  return (
    <div className="shadow px-2 py-5 rounded">
      <h5>Kullanıcı Oluştur</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid spacing={3} container>
          <Grid xs={12} sm={12} md={6} item>
            <Card className={styles.card}>
              <Stack>
                <TextField
                  required="true"
                  name="name"
                  {...register("name", { required: "İsim alanı zorunludur." })}
                  id="name"
                  label="İsim"
                  margin="normal"
                  size="small"
                  InputProps={{
                    className: styles.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: styles.input,
                  }}
                />

                {errors.name?.message && (
                  <p className="error-message">{errors.name?.message}</p>
                )}

                <TextField
                  required="true"
                  name="lastname"
                  {...register("lastname", {
                    required: "Soyisim alanı zorunludur.",
                  })}
                  label="Soyisim"
                  margin="normal"
                  size="small"
                  InputProps={{
                    className: styles.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: styles.input,
                  }}
                />

                {errors.lastname?.message && (
                  <p className="error-message">{errors.lastname?.message}</p>
                )}

                <TextField
                  required="true"
                  type="email"
                  autoComplete="false"
                  name="email"
                  {...register("email", {
                    required: "Email alanı zorunludur.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: `Geçerli bir email adresi giriniz!`,
                    },
                  })}
                  label="Email"
                  margin="normal"
                  size="small"
                  InputProps={{
                    className: styles.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlined />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: styles.input,
                  }}
                />

                {errors.email?.message && (
                  <p className="error-message">{errors.email?.message}</p>
                )}

                <TextField
                  required="true"
                  name="password"
                  margin="normal"
                  {...register("password", {
                    required: "Şifre alanı zorunludur.",
                  })}
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  size="small"
                  autoComplete="current-password"
                  InputProps={{
                    className: styles.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKey />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: styles.input,
                  }}
                />
                {errors.password?.message && (
                  <p className="error-message">{errors.password?.message}</p>
                )}

                <p>Telefon Numarası *</p>
                <Controller
                  name={"phone"}
                  control={control}
                  render={(props) => (
                    <PhoneInput
                      onChange={(e) => {
                        props.field.onChange(e);
                      }}
                      autocompleteSearch={true}
                      enableSearch={true}
                      inputProps={{
                        id: "phone",
                        name: "phone",
                        required: true,
                        autoComplete: "none",
                        "data-testid": "input-id",
                      }}
                      alwaysDefaultMask={true}
                      defaultMask={"(...) ...-..-.."}
                      inputClass={`custom-phone-input w-100`}
                      country={"tr"}
                      value={props.field.value}
                      specialLabel=""
                    />
                  )}
                  rules={{
                    required: "Telefon alanı zorunludur.",
                  }}
                />
              </Stack>
            </Card>
          </Grid>

          <Grid xs={12} sm={12} md={6} item>
            <Card className={styles.card}>
              <Stack>
                <TextField
                  name="seansType"
                  disabled
                  id="outlined-disabled"
                  label="Seans Türü"
                  margin="normal"
                  size="small"
                  defaultValue="BireBir 30dk"
                />

                <TextField
                  required="true"
                  name="seansNumber"
                  label="Seans Sayısı"
                  {...register("seansNumber", {
                    required: "Seans sayısı alanı zorunludur.",
                  })}
                  margin="normal"
                  size="small"
                  InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Numbers />
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.seansNumber?.message && (
                  <p className="error-message">{errors.seansNumber?.message}</p>
                )}

                <FormControl fullWidth margin="normal">
                  <InputLabel id="auto-renew-label">
                    Otomatik Yenileme
                  </InputLabel>
                  <Select
                    required="true"
                    name="auto_renew"
                    size="small"
                    {...register("auto_renew")}
                    margin="normal"
                    labelId="auto-renew-label"
                    id="auto-renew-select"
                    label="Otomatik Yenileme"
                    onChange={(e) => setAutoRenew(e.target.value)}
                  >
                    <MenuItem value={0}>Pasif</MenuItem>
                    <MenuItem value={1}>Aktif</MenuItem>
                  </Select>
                </FormControl>

                {autoRenew == 0 && (
                  <TextField
                    name="endDate"
                    type="date"
                    {...register("endDate", {
                      required: "Seans bitiş tarihi alanı zorunludur.",
                    })}
                    label="Seans Bitiş Tarihi"
                    size="small"
                    margin="normal"
                    required="true"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}

                {autoRenew == 0 && errors.endDate?.message && (
                  <p className="error-message">{errors.endDate?.message}</p>
                )}

                {autoRenew == 1 && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="auto-renew-day-label">
                      Yenileneceği Gün
                    </InputLabel>
                    <Select
                      required="true"
                      name="auto_renew_day"
                      size="small"
                      {...register("auto_renew_day")}
                      margin="normal"
                      labelId="auto-renew-day-label"
                      id="auto_renew_day_select"
                      label="Ayın Kaçıncı Günü?"
                    >
                      {/* make loop zero to 30 */}
                      {Array.from(Array(31).keys()).map((item, index) => (
                        <MenuItem key={index} value={item + 1}>
                          Ayın {item + 1}.günü
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <FormControl fullWidth margin="normal">
                  <InputLabel id="demo-simple-select-label">
                    Hangi Seviyesiniz?
                  </InputLabel>
                  <Select
                    required="true"
                    name="status"
                    size="small"
                    {...register("status", {
                      required: "Seviye alanı zorunludur.",
                    })}
                    margin="normal"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Hangi Seviyesiniz?"
                  >
                    <MenuItem value={1}>We Need To Talk (Başlangıç)</MenuItem>
                    <MenuItem value={2}>I Believe I Can Fly (Orta)</MenuItem>
                    <MenuItem value={3}>That's My Thing (İleri)</MenuItem>
                  </Select>
                </FormControl>

                {errors.status?.message && (
                  <p className="error-message">{errors.status?.message}</p>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  className={styles.button}
                >
                  Kullanıcı Oluştur
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateUser;
