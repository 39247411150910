import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setSiteLoading } from "../../stores/siteSlice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(setSiteLoading(true));
    axios
       .post("https://kurumapi.bukytalk.com/v1/auth/ForgotPassword",data)
      .then((result) => {
        Swal.fire("Başarılı", result.data.message, "success").then((result) => {
          navigate("/login");
        });
      })
      .catch((error) => {
        Swal.fire("Hata!",error.response.data.message, "error");
      }).finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-container">
        <input
          type="text"
          placeholder="Email"
          {...register("email", {
            required: "Email alanı boş bırakılamaz",
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: "Geçerli bir email adresi giriniz",
            },
          })}
        />
        {errors.email?.message && (
          <span className="error-message">{errors.email?.message}</span>
        )}

        <button type="submit" className="btn-login">
          Gönder
        </button>
      </div>
    </form>
  );
};

export default ForgotPassword;
