import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { GetParticipantList } from "../../api/ParticipantList";
import Button from "@mui/material/Button";
import { DataGrid, trTR } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { columns } from "./user-list-methods";
import { Collapse } from "@mui/material";
import { List, ListItemButton } from "@mui/material";
import { ListItemText } from "@mui/material";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { axiosInstanceFormData, axiosInstance } from "../../api/axios";
import { setSiteLoading } from "../../stores/siteSlice";
import {
  setTotalSessionLeft,
  setTotalUsedSessionRight,
  setTotalUsersCount,
} from "../../stores/userSlice";

import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Users() {
  const date = React.useMemo(() => new Date(), []);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [showDeleted, setShowDeleted] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getParticipantList();
  }, [showDeleted, searchText]);

  const dispatch = useDispatch();
  const [excelOperations, showExcelOperations] = useState(false);

  const getParticipantList = async () => {
    setIsLoading(true);
    await GetParticipantList(searchText, showDeleted)
      .then((response) => {
        setData(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //! BOŞ EXCEL DOSYASI
  const downloadExampleExcel = async () => {
    await axiosInstance
      .get(`/participant/example-excel`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `BukyTalk Kurumsal Örnek.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        Swal.fire("Hata!", error.response.data.message, "error");
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  //! Excel Download KULLANICI LISTESI
  const createWorkbookFromData = (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Başlık satırını ekleyin ve hücrelere stil uygula
    const titleRow = worksheet.addRow([
      "ID",
      "Ad Soyad",
      "E-posta",
      "Kalan Seans Hakkı",
      "Telefon",
    ]);
    titleRow.eachCell((cell) => {
      cell.font = { color: { argb: "FFFFFF" }, bold: true, size: 14 };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "dc3545" }, // Kırmızı arka plan rengi
      };
      cell.alignment = { horizontal: "center" }; // Metni ortala
    });

    // Her kullanıcı için bir satır ekleyin
    data.forEach((user) => {
      worksheet.addRow([
        user.id,
        user.kullaniciadsoyad,
        user.email,
        user.kalanhak,
        user.phone,
      ]);
    });

    // Her sütunun genişliğini ayarlayın
    worksheet.columns.forEach((column) => {
      column.width = 25; // Her sütunun genişliği 30 olarak ayarlanır
    });

    return workbook;
  };

  //! Excel Download KULLANICI LISTESI
  const handleDownloadExcel = async () => {
    try {
      // Verileri kullanarak bir Excel dosyası oluşturun (örneğin, exceljs kullanarak)
      const workbook = createWorkbookFromData(data);
      // Excel dosyasını indir
      const excelBuffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "KullaniciListesi.xlsx");
      Swal.fire("Başarılı!", "Excel dosyası başarıyla indirildi!", "success");
    } catch (error) {
      console.error("Excel indirme hatası:", error);
      Swal.fire("Hata!", "Excel dosyası indirilemedi.", "error");
    }
  };

  //! PDF Download KULLANICI LISTESI
  const createPdfFromData = (data) => {
    const documentDefinition = {
      content: [
        {
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto", "auto"],
            body: [
              [
                { text: "ID", style: "tableHeader" },
                { text: "Ad Soyad", style: "tableHeader" },
                { text: "E-posta", style: "tableHeader" },
                { text: "Kalan Seans Hakkı", style: "tableHeader" },
                { text: "Telefon", style: "tableHeader" },
              ],
              ...data.map((user) => [
                user.id,
                user.kullaniciadsoyad,
                user.email,
                user.kalanhak,
                user.phone,
              ]),
            ],
          },
        },
      ],
      styles: {
        tableHeader: {
          bold: true,
          fillColor: "#DC3545",
          color: "#FFFFFF",
        },
      },
    };

    return documentDefinition;
  };

  //! PDF Download KULLANICI LISTESI
  const handleDownloadPDF = async () => {
    try {
      // Verileri kullanarak bir PDF belgesi oluşturun
      const documentDefinition = createPdfFromData(data);
      // PDF dosyasını oluştur
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      // PDF dosyasını indir
      pdfDocGenerator.download("KullaniciListesi.pdf");
      // İndirme başladığında popup göster
      Swal.fire("Başarılı!", "PDF dosyası başarıyla indirildi!", "success");
    } catch (error) {
      console.error("PDF indirme hatası:", error);
      // Hata durumunda hata mesajını göster
      Swal.fire("Hata!", "PDF dosyası indirilemedi.", "error");
    }
  };

  const activeExcelPicker = () => {
    document.getElementById("excel-upload").click();
  };

  const handleFileUpload = async (e) => {
    dispatch(setSiteLoading(true));
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    await axiosInstanceFormData
      .post("/participant/excel", formData)
      .then((res) => {
        Swal.fire("Başarılı!", res.data.message, "success");
        getParticipantList();
        dispatch(setTotalUsersCount(null));
        dispatch(setTotalSessionLeft(null));
        dispatch(setTotalUsedSessionRight(null));
      })
      .catch((error) => {
        Swal.fire("Hata!", error.response.data.message, "error");
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
        document.getElementById("excel-upload").value = "";
      });
  };

  return (
    <div className="border rounded bg-white p-3" style={{ height: "600px" }}>
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid xs={12} sm={12} md={12}>
          <Box sx={{ height: 520, width: "98%" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <Button
                className="userlistBtn"
                onClick={() => navigate("/user-add")}
                variant="outlined"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "5px",
                  height: "fit-content",
                }}
              >
                Kullanıcı Ekle
              </Button>
              <Button
                className="userlistBtn"
                onClick={handleDownloadPDF}
                variant="outlined"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "5px",
                  height: "fit-content",
                }}
              >
                PDF İNDİR
              </Button>
              <div className="d-flex flex-row">
                <Button
                  className="userlistBtn"
                  onClick={() => showExcelOperations(!excelOperations)}
                  variant="outlined"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "5px",
                    height: "fit-content",
                  }}
                >
                  Excel İşlemleri
                </Button>
                <Collapse in={excelOperations} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      onClick={downloadExampleExcel}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText>Örnek Excel İndir</ListItemText>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} onClick={activeExcelPicker}>
                      <ListItemText>Excel ile Kullanıcı Yükleme</ListItemText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={handleDownloadExcel}
                    >
                      <ListItemText>
                        Excel olarak Kullanıcıları İndir
                      </ListItemText>
                    </ListItemButton>
                  </List>
                  <input
                    id="excel-upload"
                    type="file"
                    style={{ display: "none" }}
                    accept=".xlsx"
                    onChange={handleFileUpload}
                  />
                </Collapse>
                <FormControlLabel
                  style={{
                    height: "fit-content",
                    marginLeft: "1rem",
                  }}
                  onChange={(e) => setShowDeleted(e.target.checked)}
                  control={<Checkbox checked={showDeleted} />}
                  label="Silinmiş Kullanıcıları Göster"
                />
              </div>

              <TextField
                autoComplete=""
                defaultValue={""}
                className="mb-2"
                style={{
                  marginLeft: "auto",
                }}
                id="outlined-basic"
                label="Arama"
                variant="outlined"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            {isLoading ? (
              <div
                style={{
                  height: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                className="userlistTable"
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                onCellDoubleClick={(params) =>
                  navigate(`/edit-user/${params.id}`)
                }
                /*onChangePage*/
                onPageSizeChange={(params) => {
                  setPageSize(params);
                }}
                disableSelectionOnClick={true}
                // onRowClick={(rowData) => this.handleClickOpenFmConfigForm(rowData.row)}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Users;
