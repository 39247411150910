import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setUser } from "../../stores/userSlice";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { setSiteLoading } from "../../stores/siteSlice";
import PasswordInput from "../../components/password-input/PasswordInput";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data) => {
    dispatch(setSiteLoading(true));
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/Login`, data)
      .then((result) => {
        localStorage.setItem("token", result.data.data);
        const decrypToken = jwtDecode(result.data.data);
        dispatch(setUser({ ...decrypToken, token: result.data.data }));
        navigate("/");
      })
      .catch((error) => {
        Swal.fire("Hata!", error.response.data.message, "error");
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-container">
        <input
          {...register("email", {
            required: `Email alanı boş bırakılamaz!`,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: `Geçerli bir email adresi giriniz!`,
            },
          })}
          type="text"
          name="email"
          placeholder="Email"
        />
        {errors.email?.message && (
          <p className="error-message">{errors.email?.message}</p>
        )}
        <PasswordInput register={register} placeholder={'Şifre Giriniz'} name="password" errors={errors} showPassword={showPassword} setShowPassword={setShowPassword} />
        <button className="btn-login">Giriş Yap</button>
        <Link to="/forgot-password">Şifremi Unuttum?</Link>
      </div>
    </form>
  );
};

export default Login;
