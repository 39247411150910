import React, { useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  CardLink,
} from "reactstrap";
import { Button, CardContent, Typography, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetCountParticipant } from "../../api/GetCountParticipant";
import { GetTotalCountRemainingSessions } from "../../api/TotalCountRemainingSessions";
import { GetLastDayOfUse } from "../../api/LastDayOfUse";
import Moment from "moment";
import { useSelector } from "react-redux";
import TotalUserCount from "../../components/total-user-count/TotalUserCount";
import TotalSessionLeft from "../../components/total-session-left/TotalSessionLeft";
import LastDayOfUse from "../../components/last-day-of-use/LastDayOfUse";
import TotalUsedSessionRight from "../../components/total-used-session-right/TotalUsedSessionRight";
function Profile() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    Toplam_Kayıt: 0,
    Toplam_Kayıt_Loading: true,
    Toplam_Kayıt_HasError: false,
    KalanSeansSayisi: 0,
    KalanSeansSayisi_Loading: true,
    KalanSeansSayisi_HasError: false,
    sonkullanimtarihi: 0,
    sonkullanimtarihi_Loading: true,
    sonkullanimtarihi_HasError: false,
  });

  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countParticipant, totalCountRemainingSessions, lastDayOfUse] =
          await Promise.all([
            GetCountParticipant(),
            GetTotalCountRemainingSessions(),
            GetLastDayOfUse(),
          ]);
        setData((prevState) => ({
          ...prevState,
          Toplam_Kayıt: countParticipant[0].Toplam_Kayıt,
          KalanSeansSayisi: totalCountRemainingSessions[0].KalanSeansSayisi,
          sonkullanimtarihi: lastDayOfUse[0].sonkullanimtarihi,
        }));
      } catch (error) {
        setData((prevState) => ({
          ...prevState,
          Toplam_Kayıt_HasError: error[0]
            ? true
            : prevState.Toplam_Kayıt_HasError,
          KalanSeansSayisi_HasError: error[1]
            ? true
            : prevState.KalanSeansSayisi_HasError,
          sonkullanimtarihi_HasError: error[2]
            ? true
            : prevState.sonkullanimtarihi_HasError,
        }));
      } finally {
        setData((prevState) => ({
          ...prevState,
          Toplam_Kayıt_Loading: false,
          KalanSeansSayisi_Loading: false,
          sonkullanimtarihi_Loading: false,
        }));
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="text-center card-body d-flex justify-content-center flex-column align-items-center">
              <img
                id="userImg"
                src={`https://cdn.bukytalk.com/images/institution/userphoto/${user.photo}`}
                style={{ borderRadius: "100%" }}
                width={138.89}
                height={150}
              />
              <p>{user.kisaisim}</p>

              <p>
                {user.name} {user.lastname}
              </p>

              <p></p>
            </div>
            <div className="card-footer d-flex justify-content-center align-items-center gap-2">
              <Button
                variant="contained"
                style={{
                  background: "#dc3545",
                }}
                onClick={() => navigate("/edit-profile")}
              >
                <span className="profileLink">Profili Düzenle</span>
              </Button>
              <Button
                onClick={() => navigate("/kurum/update-password")}
                variant="contained"
                style={{
                  background: "#dc3545",
                }}
              >
                <span className="profileLink">Şifremi Değiştir</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6">
              <TotalUserCount />
            </div>
            <div className="col-md-6">
              <TotalUsedSessionRight />
            </div>
            <div className="col-md-6 mt-4">
              <TotalSessionLeft />
            </div>
            <div className="col-md-6 mt-4">
              <LastDayOfUse />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
