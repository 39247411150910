import React, { Component } from 'react'
import './footer.css';

export default class Footer extends Component {
  render() {
    return (
      <div>
      <footer id="sticky-footer" className="flex-shrink-0 py-4  text-white-50">
        <div className="container text-center">
          <small>Bukytalk &copy; 2023</small>
        </div>
      </footer>
    </div>
    )
  }
}
