import { useState, useEffect, useMemo } from "react";
import { Button, Grid, TextField,FormControlLabel,Checkbox } from "@mui/material";
import Moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { GetParticipantRemainingSeansHaklari } from "../../api/ParticipantRemainingSeansHaklari";
import { DataGrid, trTR } from "@mui/x-data-grid";
import { axiosInstance } from "../../api/axios";
import { useDispatch } from "react-redux";
import { setSiteLoading } from "../../stores/siteSlice";
import Swal from "sweetalert2";

function UsersSessionRightTable() {
  const dispatch = useDispatch();
  const date = useMemo(() => new Date(), []);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  //todo mert pagination ekle
  const columns = useMemo(() => [
    {
      field: "kullaniciadsoyad",
      headerName: "Ad Soyad",
      width: 230,
      editable: false,
    },
    {
      field: "kullanilanseanssayisi",
      headerName: "Kullanılan Seans Sayısı",
      width: 230,
      editable: false,
    },
    {
      field: "kalanhak",
      headerName: "Kalan Seans Hak",
      width: 230,
      editable: false,
    },
    {
      field: "bitisTarihi",
      headerName: "Bitiş Tarihi",
      width: 230,
      editable: false,
    },
  ]);
  const [data, setData] = useState([]);
  const [pageList, setPageList] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const exportToExcel = async () => {
    dispatch(setSiteLoading(true));
    await axiosInstance
      .get(`/reports/participantSessionsRight?search=${searchText}&showDeleted=${showDeletedUsers}`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Kullanici Seans Hakları-${date.toLocaleDateString()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        Swal.fire("Hata!", error.response.data.message, "error");
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  useEffect(() => {
    getParticipantRemainingSeansHaklari();
  }, [searchText, showDeletedUsers]);

  const getParticipantRemainingSeansHaklari = async () => {
    console.log("here we go")
    setLoading(true);
    await GetParticipantRemainingSeansHaklari(searchText, showDeletedUsers)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="border rounded p-3">
      <div className="d-flex justify-content-between">
        <Button
          onClick={exportToExcel}
          className="text-white"
          variant="outlined"
          color="error"
          style={{
            background: "#dc3545",
          }}
        >
          EXCEL İNDİR
        </Button>
        <FormControlLabel
          style={{
            height: "fit-content",
            marginLeft: "1rem",
          }}
          onChange={(e) => {
            setShowDeletedUsers(e.target.checked);
          }}
          control={<Checkbox checked={showDeletedUsers} />}
          label="Silinmiş Kullanıcıları Göster"
        />
      </div>

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
        <div className="d-flex justify-content-end w-100">
          <TextField
            className="my-2"
            style={{
              marginLeft: "auto",
            }}
            id="outlined-basic"
            label="Arama"
            variant="outlined"
            defaultValue=""
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <Grid xs={12} sm={12} md={12}>
          {loading && (
            <div
              style={{
                minHeight: "600px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && data.length === 0 && (
            <div className="text-center mt-3">Kayıt Bulunamadı</div>
          )}

          {!loading && data.length !== 0 && (
            <div>
              <DataGrid
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                rows={data}
                columns={columns}
                pageSize={pageList}
                onPageSizeChange={(newPageSize) => setPageList(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                autoHeight
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default UsersSessionRightTable;
