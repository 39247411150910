import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../api/axios";
import {
  InputLabel,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Grid,
} from "@mui/material";
import { VpnKey } from "@mui/icons-material";
import alertify from "alertifyjs";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setSiteLoading } from "../../stores/siteSlice";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import {
  setUser,
  setTotalUsersCount,
  setTotalSessionLeft,
  setLastDayOfUse,
  setTotalUsedSessionRight,
} from "../../stores/userSlice";
import PasswordInput from "../../components/password-input/PasswordInput";

function UpdatePassword() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    dispatch(setSiteLoading(true));
    axiosInstance
      .put("/corporations/password", data)
      .then((result) => {
        Swal.fire("Başarılı", result.data.message, "success").then((result) => {
          localStorage.removeItem("token");
          dispatch(setUser(undefined));
          dispatch(setTotalUsersCount(null));
          dispatch(setTotalSessionLeft(null));
          dispatch(setLastDayOfUse(null));
          dispatch(setTotalUsedSessionRight(null));
          navigate("/login");
        });
      })
      .catch((error) => {
        Swal.fire("Hata!", error.response.data.message, "error");
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  return (
    <div className="border rounded bg-white p-3">
      <Grid
        container
        columns={{ xs: 12, sm: 12, md: 12 }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid xs={12} sm={12} md={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <h2 className="header text-center">Şifremi Değiştir</h2>
              <div className="form-container">
                <PasswordInput
                  register={register}
                  placeholder={"Eski Şifreniz"}
                  name={"oldPassword"}
                  errors={errors}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
                <PasswordInput
                  register={register}
                  placeholder={"Yeni Şifrenizi Giriniz"}
                  name={"newPassword"}
                  errors={errors}
                  showPassword={showNewPassword}
                  setShowPassword={setShowNewPassword}
                />
                <PasswordInput
                  register={register}
                  placeholder={"Yeni Şifrenizi Tekrar Giriniz"}
                  name={"rePass"}
                  errors={errors}
                  showPassword={showNewPassword2}
                  setShowPassword={setShowNewPassword2}
                  customRegisterProps={{
                    validate: (value) => {
                      const { newPassword } = getValues();
                      return newPassword === value || `Şifreler Uyuşmuyor!`;
                    },
                  }}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                style={{
                  marginTop: "15px",
                  background: "#dc3545",
                }}
              >
                Güncelle
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default UpdatePassword;
