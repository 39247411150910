import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../api/axios";
import { Card, CircularProgress } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function JoinSessionChart() {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Seansa Giren Kullanıcı Sayısı",
      },
    },
    scales: {
      y: {
          beginAtZero: true,
          ticks: {
              stepSize: 1,
          }
      },
    },
  };

  const fetchDays = () => {
    const today = new Date();
    const old = new Date();
    old.setDate(today.getDate() - 7);

    const dates = [];

    while (old <= today) {
      const date = old.toISOString().slice(0, 10);
      const formattedDate = date.split("-").reverse().join(".");
      dates.push(formattedDate);
      old.setDate(old.getDate() + 1);
    }

    setLabels(dates);
  };

  const fetchJoinSessionCount = () => {
    setLoading(true);
    axiosInstance.get("/participant/join")
      .then((res) => {
        setData(res.data.data);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDays();
    fetchJoinSessionCount();
  }, []);

  return (
    <div
      style={{
        minHeight: "400px",
      }}
    >
      {loading && <div className="d-flex justify-content-center align-items-center" style={{minHeight:'400px'}}>
          <CircularProgress />
        </div>}
      {!loading &&labels.length > 0 && data.length > 0 && (
        <Line
        options={options}
        data={{
          labels,
          datasets: [
            {
              label: "Seansa Giren Kullanıcı Sayısı",
              data,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        }}
      />
        )}
    </div>
  );
}

export default JoinSessionChart;
