import classes from "./sidebar.module.css";
import bukyLogo from "../../assets/logo/logokucuk.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { menuItems } from "./menu-items";
import SidebarItem from "./SidebarItem";
import { CloseButton, Nav } from "reactstrap";
import { setMobileNavOpen } from "../../stores/siteSlice";

function Sidebar() {
  const { mobileNavOpen } = useSelector((state) => state.site);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Nav className={!mobileNavOpen ? classes.sidebar : classes.sidebar__mobile }>
      {mobileNavOpen && <div className={classes.sidebar__close__button}>
        <CloseButton onClick={() => dispatch(setMobileNavOpen(false))} />
      </div>}
      <div onClick={() => navigate("/")} className={classes.sidebar__logo}>
        <img src={bukyLogo} alt="buky logo" />
      </div>
      <ul className={classes.sidebar__nav__list}>
        {menuItems.map((item, index) => (
          <SidebarItem key={index} {...item} />
        ))}
      </ul>
    </Nav>
  );
}
export default Sidebar;
