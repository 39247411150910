
import React from 'react'
import styles from './page-loading.module.css'
import CircularProgress from '@mui/material/CircularProgress';
function PageLoading() {
  return (
    <div className={styles.page__loading}>
    <div className={styles.page__loading__spinner}>
      <CircularProgress />
    </div>
  </div>
  )
}

export default PageLoading