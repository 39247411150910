import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    siteLoading: false,
    mobileNavOpen: false,
    siteSideWidth: 240,
};

const siteSlice = createSlice({
    name: "site",
    initialState: initalState,
    reducers: {
        setSiteLoading(state, action) {
            state.siteLoading = action.payload;
        },
        setMobileNavOpen(state, action) {
            state.mobileNavOpen = action.payload;
        }
    }
});

export const {setSiteLoading,setMobileNavOpen} = siteSlice.actions;

export default siteSlice;