import React from "react";
import { Card, CardActions, CardContent, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTotalUsedSessionRight } from "../../stores/userSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { GetKurumTotalUsedSeansCount } from "../../api/KurumTotalUsedSeansCount";

function TotalUsedSessionRight() {
  const { totalUsedSessionRight } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (totalUsedSessionRight == null) {
      getTotalUsedSessionRight();
    }
  }, [totalUsedSessionRight]);

  const getTotalUsedSessionRight = async () => {
    GetKurumTotalUsedSeansCount().then((res) => {
      dispatch(setTotalUsedSessionRight(res[0].ToplamSeansSayısı));
    });
  };

  return (
    <Card className="infoCard">
      <CardContent>
        <Typography
          padding={1}
          component="div"
          style={{
            background: "#ea484d",
            color: "#fff",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <span>Toplam Kullanılan Seans</span>
        </Typography>
        <Typography
          variant="h5"
          component="div"
          marginTop={2}
          textAlign="center"
        >
          {totalUsedSessionRight == null ? (
            <CircularProgress />
          ) : (
            totalUsedSessionRight
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={{
            color: "#9b9aba",
            fontSize: "12px",
            fontWeight: "400",
            margin: "auto",
          }}
        >
          <span className="profileCardText">Toplam Kullanılan Seans Hakkı</span>
        </Button>
      </CardActions>
    </Card>
  );
}

export default TotalUsedSessionRight;
