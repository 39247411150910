import {
  Toolbar,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  AppBar,
  CssBaseline,
  IconButton,
  Box,
  Drawer,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import {
  MdModeEdit,
  MdOutlineLockOpen,
  MdPowerSettingsNew,
  MdSupervisedUserCircle,
} from "react-icons/md";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Header.module.css";
import { useDispatch } from "react-redux";
import { setUser, setTotalUsersCount, setTotalSessionLeft, setLastDayOfUse, setTotalUsedSessionRight } from "../../stores/userSlice";
import { setMobileNavOpen } from "../../stores/siteSlice";


function Header() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user } = useSelector((state) => state.user);
  const {mobileNavOpen} = useSelector((state) => state.site);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
      <Box
        style={{ background: "#ea484d", paddingY: "10px"}}
        className="appBar"
      >
        <Toolbar>
          <IconButton
            style={{ color: "#fff" }}
            className={styles.menuButton}
            aria-label="open drawer"
            edge="start"
            onClick={() => dispatch(setMobileNavOpen(!mobileNavOpen))}
          >
            <MenuIcon />
          </IconButton>
          <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            
          }}
          >
            <Typography variant="h6" noWrap component="div" color={"MenuText"}>
                <img
                  src={`https://cdn.bukytalk.com/images/institution/logo/${user?.logo}`}
                  width={50}
                  height={50}
                />
            </Typography>
            <Typography variant="h6" noWrap component="div">
              <h3 className={styles.navHeader}>BukyTalk Kurumsal</h3>
            </Typography>
            <Tooltip title="Profil" className={styles.userProfilePic}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <div>
                  <img
                    src={`https://cdn.bukytalk.com/images/institution/logo/${user?.logo}`}
                    className={styles.userProfile}
                  />
                </div>
              </IconButton>
            </Tooltip>
          </div>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              className={styles.sidebarMenu}
              onClick={() => {
                navigate("/profile");
              }}
            >
              <Typography>
                <MdSupervisedUserCircle className={styles.sidebarMenuIcon} />
                <Link className={styles.sidebarMenuLink} to="/profile">
                  Profil
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={styles.sidebarMenu}>
              <Typography>
                <MdModeEdit className={styles.sidebarMenuIcon} />
                <Link to="/edit-profile" className={styles.sidebarMenuLink}>
                  Profilimi Düzenle
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={styles.sidebarMenu}>
              <Typography>
                <MdOutlineLockOpen className={styles.sidebarMenuIcon} />
                <Link className={styles.sidebarMenuLink} to="/kurum/update-password">
                  Şifremi Değiştir
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem
              className={styles.sidebarMenu}
              onClick={() => {
                localStorage.removeItem("token");
                dispatch(setUser(undefined));
                dispatch(setTotalUsersCount(null));
                dispatch(setTotalSessionLeft(null));
                dispatch(setLastDayOfUse(null));
                dispatch(setTotalUsedSessionRight(null));
                navigate("/login");
              }}
            >
              <MdPowerSettingsNew className={styles.sidebarMenuIcon} />
              <Typography>
                <span className={styles.sidebarMenuLink}>
                  Çıkış
                </span>
              </Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </Box>
  );
}

export default Header;
