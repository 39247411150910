import React from 'react'
import {
    InputLabel,
    Stack,
    TextField,
    InputAdornment,
    Button,
    Grid,
  } from "@mui/material";
  import { AccountCircle, MailOutlined } from "@mui/icons-material";
  import { useNavigate } from "react-router-dom";
  import { useEffect } from "react";
  import {axiosInstance} from "../../api/axios";
  import alertify from "alertifyjs";
  import { useForm } from "react-hook-form";
  import { useDispatch } from "react-redux";
  import { setSiteLoading } from "../../stores/siteSlice";
  
function EditProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm();
    
    useEffect(() => {
      GetKurumListid();
    }, []);
  
    function GetKurumListid() {
      dispatch(setSiteLoading(true));
      axiosInstance.get("/corporations").then((res) => {
        console.log()
        setValue("name", res.data[0].name);
        setValue("lastname", res.data[0].lastname);
        setValue("email", res.data[0].email);
      }).finally(() => {
        dispatch(setSiteLoading(false));
      });
    }
  
    const onSubmit = (data) => {
      alertify.confirm(
        "Güncelleme Yapılsın Mı?",
        `${data.name} ${data.lastname} isimli kullanıcı güncellensin mi?`,
        function () {
          dispatch(setSiteLoading(true));
          axiosInstance({
            method: "PUT",
            url: "/corporations",
            data
          })
            .then(() => {
              alertify.success("Güncelleme Başarılı.");
              navigate("/profile");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              dispatch(setSiteLoading(false));
            });
        },
        function () {
          alertify.error("Güncelleme İptal Edildi.");
        }
      );
    };
  
    return (
      <div className="border rounded bg-white p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-center text-danger">Profilimi Düzenle</h2>
          <Grid container columns={{ xs: 12, sm: 12, md: 12 }} style={{display:'flex', justifyContent: 'center'}}>
            <Grid xs={12} sm={6} md={6}>
              <Stack>
                <InputLabel>İsim</InputLabel>
                <TextField
                  color="warning"
                  required="true"
                  name="name"
                  id="input-with-icon-adornment"
                  margin="normal"
                  size="small"
                  {...register("name", { required: "İsim alanı boş bırakılamaz!" })}
                  InputProps={{
                    style: { color: "#78a6c8" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "#78a6c8" },
                  }}
                />
  
                <InputLabel>Soyisim</InputLabel>
                <TextField
                  color="warning"
                  required="true"
                  name="lastname"
                  {...register("lastname", { required: "Soyisim alanı boş bırakılamaz!" })}
                  id="input-with-icon-adornment"
                  margin="normal"
                  size="small"
                  InputProps={{
                    style: { color: "#78a6c8" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "#78a6c8" },
                  }}
                />
  
                <InputLabel>Email</InputLabel>
                <TextField
                  color="warning"
                  required="true"
                  autoComplete="false"
                  name="email"
                  {...register("email", 
                  { 
                    required: "Email alanı boş bırakılamaz!",
                    pattern: {
                      value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                      message: "Geçerli bir email adresi giriniz!",
                    },
                  })}
                  margin="normal"
                  size="small"
                  InputProps={{
                    style: { color: "#78a6c8" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlined />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "#78a6c8" },
                  }}
                />
  
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    marginTop: "15px",
                    background: "#dc3545",
                  }}
                >
                  Güncelle
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </div>
    );
}

export default EditProfile