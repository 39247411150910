import { Toolbar, Box } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GetKurumListid } from "../api/KurumListid";
import { useDispatch } from "react-redux";
import { setUser } from "../stores/userSlice";
import { setSiteLoading } from "../stores/siteSlice";
import { Row, Col } from "reactstrap";
import styles from './main-layout.module.css'

function MainLayout() {
  const { siteSideWidth,mobileNavOpen } = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (user == undefined) {
      getUser();
    }
  }, [user]);

  const getUser = async () => {
    dispatch(setSiteLoading(true));
    GetKurumListid()
      .then((res) => {
        dispatch(setUser(res));
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  return (
    <Row className={styles.main__row}>
      <Col md={4} lg={3} xl={2} className={mobileNavOpen ? styles.mobile__nav : ''}>
        <Sidebar />
      </Col>
      <Col md={8} lg={9} xl={10} className={styles.main__section}>
        {user != undefined && token && (
          <Box
            style={{ background: "white", height: "960px"}}
            component="main"
          >
            {user != undefined && <Header />}
            <Col md={12}>
              <Toolbar />
            </Col>
            <Col style={{
              "padding":"1rem"
            }}>
            <Outlet />
            </Col>
          </Box>
        )}
      </Col>
    </Row>
  );
}

export default MainLayout;
