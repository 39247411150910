import React, { useMemo, useState } from "react";
import { axiosInstance } from "../../api/axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import styles from "./progress-report.module.css";
import { AiOutlineMinus, AiOutlineArrowUp } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setSiteLoading } from "../../stores/siteSlice";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function ProgressReport() {
  const [labels,setLabels] = useState([]);
  const levels = useMemo(() => ['Başlangıç', 'Orta', 'İleri']);

  const options = React.useMemo(() => ({
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Kullanıcı Seviye Gelişimi",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value, index, values) {
            return levels[value - 1]; 
          },
          max: 3,
        },
      },
    },
  }));

  const { userid } = useParams();
  const [data, setData] = React.useState(null);
  const dispatch = useDispatch();
  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  useEffect(() => {
    getProgressReport(userid);
  }, [userid]);

  const getProgressReport = (userid) => {
    dispatch(setSiteLoading(true));
    axiosInstance
      .get(`/reports/progressReport/${userid}`)
      .then((res) => {
        let data = res.data.data.highest_level;
        let labels = [];
        /*order by year and month*/
        data.sort((a, b) => {
          if (a.year > b.year) {
            return 1;
          } else if (a.year < b.year) {
            return -1;
          } else {
            if (a.month > b.month) {
              return 1;
            } else if (a.month < b.month) {
              return -1;
            } else {
              return 0;
            }
          }
        });
        data.forEach((item) => {
          labels.push(`${item.year}-${monthNames[item.month - 1]}`);
        });
        setLabels(labels);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  const findTheIconByRate = (rate) => {
    if (rate == 0) return <AiOutlineMinus />;
    else if (rate > 0) return <AiOutlineArrowUp />;
  };

  return (
    <>
      {data != null && (
        <div className="row p-4">
          <div
            className={`${styles.user__card} card shadow col-md-4 text-center d-flex justify-content-center align-items-center`}
          >
            <div className="w-100">
              <h4 className="mt-5 mb-3">Kullanıcı Bilgileri</h4>
              <img
                src={`https://cdn.bukytalk.com/images/talker/${data.talker?.photo}`}
                alt="user photo"
                className={styles.user__photo}
              />
              <h5 className="mt-4 mb-2">
                {data.talker?.name} {data.talker?.lastname}
              </h5>
              <h5 className="my-2">{data.talker?.email}</h5>
              <h5 className="mt-2 mb-5">{data.talker?.phone}</h5>
            </div>
          </div>
          <div className="col-md-8">
            <div className="w-100">
              <div className="row">
                <div className="col-md-6">
                  <div className="card shadow p-4 text-center">
                    <h5>Bu Hafta Girilen Seans Sayısı</h5>
                    <h5>
                      <span>{data.weekly_session_join.buhafta}</span>
                      <span className="mx-2">
                        {findTheIconByRate(data.weekly_session_join.rate)}
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card shadow p-4 text-center">
                    <h5>Bu Ay Girilen Seans Sayısı</h5>
                    <h5>
                      <span>{data.monthly_session_join.buay}</span>
                      <span className="mx-2">
                        {findTheIconByRate(data.monthly_session_join.rate)}
                      </span>
                      <span className={styles.rate__text}>
                        % {data.monthly_session_join.rate}
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card shadow p-4 mt-1 text-center">
                    <Line
                      options={options}
                      data={{
                        labels,
                        datasets: [
                          {
                            label: "Kullanıcının Seviyesi",
                            data: data.highest_level.map((item) => item.level),
                            borderColor: "rgb(255, 99, 132)",
                            backgroundColor: "rgba(255, 99, 132, 0.5)",
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProgressReport;
