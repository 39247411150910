/*
<ListItemButton
          className="sidebarButton"
          id="profile"
          onClick={() => {
            navigate("/profile");
          }}
        >
          <ListItemIcon>
            <MdSupervisedUserCircle
              size={20}
              className="sidebarIcon"
              id="profileBtn"
            />
          </ListItemIcon>
          <ListItemText>
            <a href="/profile" className="sidebarLink" id="sidebarProfileTxt">
              Profil
            </a>
          </ListItemText>
        </ListItemButton>*/

import { MdHome, MdSupervisedUserCircle,MdGroups, MdPieChart } from "react-icons/md";

export const menuItems = [
    {
        "id":"home",
        "icon":<MdHome size={20} className="sidebarIcon" id="homeBtn" />,
        "href":"/",
        "text":"Anasayfa"
    },
    {
      "id":"profil",
      "icon":<MdSupervisedUserCircle size={20} className="sidebarIcon" id="profileBtn" />,
      "href":"/profile",
      "text":"Profil"
    },
    {
      "id":"kullanıcılar",
      "icon":<MdGroups size={20} className="sidebarIcon" id="kullanicilarBtn" />,
      "href":"/users",
      "text":"Kullanıcılar"
    },
    {
      "id":"raporlar",
      "icon":<MdPieChart size={20} className="sidebarIcon" id="raporlarBtn" />,
      "text":"Raporlar",
      "subMenu":[
        {
          "id":"user-feedbacks",
          "icon":<MdPieChart size={20} className="sidebarIcon" id="rapor1Btn" />,
          "href":"/reports/user-feedbacks",
          "text":"Kullanıcı Geri Bildirimleri"
        },
        {
          "id":"user-session-rights",
          "icon":<MdPieChart size={20} className="sidebarIcon" id="rapor2Btn" />,
          "href":"/reports/user-session-rights",
          "text":"Kullanıcı Seans Hakları"
        },
      ]
    } 
];