import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function PasswordInput({
  register,
  customRegisterProps,
  placeholder,
  name,
  errors,
  showPassword,
  setShowPassword,
}) {
  return (
    <>
      <div className="position-relative">
        <input
          {...register(name, {
            required: `Şifre alanı boş bırakılamaz!`,
            ...customRegisterProps || {},
          })}
          type={showPassword == true ? "text" : "password"}
          name={name}
          placeholder={placeholder}
        />
        <div
          className="position-absolute"
          onClick={() => setShowPassword(!showPassword)}
          style={{
            right: "10px",
            bottom: "17px",
            cursor: "pointer",
          }}
        >
          {showPassword == true ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>

      {errors[name]?.message && (
        <p className="error-message">{errors[name]?.message}</p>
      )}
    </>
  );
}

export default PasswordInput;
