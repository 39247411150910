import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const data = {
  baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

const axiosInstance = axios.create(data);

const axiosInstanceFormData = axios.create({
  ...data,
  ...{
    headers: {
      ...data.headers,
      "Content-Type": "multipart/form-data",
    },
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if(err.response.status == 403 || err.response.status == 401) {
    localStorage.removeItem("token");
    window.location.href = "/login";
    alert("Tekrar giriş yapmanız gerekmektedir.");
  }
    return Promise.reject(err);
  }
);

export { axiosInstance, axiosInstanceFormData };
