import React from 'react'
import { Grid,Card } from '@mui/material'
import TotalUserCount from '../../components/total-user-count/TotalUserCount'
import TotalSessionLeft from '../../components/total-session-left/TotalSessionLeft'
import LastDayOfUse from '../../components/last-day-of-use/LastDayOfUse'
import JoinSessionChart from '../../components/join-session-chart/JoinSessionChart'
import ReducedUserSessionCount from '../../components/reduced-user-session-count/ReducedUserSessionCount'

function Home() {
    return (
        <div>
          <Grid
            container
            spacing={{ md: 1 }}
            columns={{ xs: 6, sm: 10, md: 12 }}
            display="flex"
            justifyContent="space-around"
          >
            <Grid xs={12} sm={10} md={3}>
              <TotalUserCount />
            </Grid>
            <Grid xs={12} sm={10} md={3}>
              <TotalSessionLeft />
            </Grid>
            <Grid xs={12} sm={10} md={3}>
              <LastDayOfUse />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={{ md: 1 }}
            columns={{ xs: 6, sm: 10, md: 12 }}
            padding={{ md: "3rem" }}
          >
            <Grid xs={12} sm={10} md={8} item>
              <Card>
              <JoinSessionChart />
              </Card>
              
            </Grid>
            <Grid xs={12} sm={10} md={4} item>
              <Card>
              <ReducedUserSessionCount />
              </Card>
              
            </Grid>
          </Grid>

        </div>
      );
}

export default Home