import React from "react";
import { Card, CardActions, CardContent, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { GetCountParticipant } from "../../api/GetCountParticipant";
import { setTotalUsersCount } from "../../stores/userSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

function TotalUserCount() {
  const {totalUsersCount} = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(totalUsersCount == null) {
      getTotalUsersCount();
    }
  }, [totalUsersCount]);

  const getTotalUsersCount = async () => {
    GetCountParticipant()
      .then((res) => {
        dispatch(setTotalUsersCount(res[0].Toplam_Kayıt));
      })
  };

  return (
    <Card className="homeCard">
      <CardContent>
        <Typography
          padding={1}
          component="div"
          style={{
            background: "#ea484d",
            color: "#fff",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <span>Toplam Kullanıcı Sayısı</span>
        </Typography>
        <Typography
          variant="h5"
          component="div"
          marginTop={2}
          textAlign="center"
        >
            {totalUsersCount == null ? <CircularProgress /> : totalUsersCount}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => navigate('/users') }
          size="small"
          style={{
            color: "#9b9aba",
            fontSize: "12px",
            fontWeight: "400",
            margin: "auto",
          }}
        >
          <span className="homeCardText">
            Listeyi Gör
          </span>
        </Button>
      </CardActions>
    </Card>
  );
}

export default TotalUserCount;
