import { ListItemButton } from "@mui/material";
import React from "react";
import { ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { Collapse, List } from "@mui/material";
import styles from "./sidebar.module.css";
import { useDispatch } from "react-redux";
import { setMobileNavOpen } from "../../stores/siteSlice";

function SidebarItem({ id, icon, href, text, subMenu }) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectTo = (href) => {
    if (href) {
      dispatch(setMobileNavOpen(false));
      navigate(href);
    }
    else {
      setOpen(!open);
    }
  };
  return (
    <>
      <ListItemButton
        id={id}
        className={styles.sidebarButton}
        onClick={() => redirectTo(href)}
      >
        <ListItemIcon
        style={{
          minWidth:"unset"
        }}
        >{icon}</ListItemIcon>
        <ListItemText>
          <a className={styles.sidebarLink}>{text}</a>
        </ListItemText>
        {subMenu && subMenu.length > 0 && (
          <>
            {open ? (
              <MdExpandLess className={styles.sidebarIcon} />
            ) : (
              <MdExpandMore className={styles.sidebarIcon} />
            )}
          </>
        )}
      </ListItemButton>
      {subMenu && subMenu.length > 0 && (
        <>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subMenu.map((item) => (
                <ListItemButton
                  onClick={() => redirectTo(item.href)}
                  sx={{ pl: 4 }}
                  className={styles.sidebarButton}
                >
                  <ListItemText>
                  <a className={styles.sidebarLink}>{item.text}</a>
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
}

export default SidebarItem;
