import "./App.css";
import {
  Route, Routes, useLocation,
} from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import PageLoading from "./components/page-loading/PageLoading";
import {useSelector} from "react-redux";
import { Users,UpdateUser,Login,ForgotPassword,ResetPassword,Home, UserFeedback,UserSessionRight,EditProfile,UpdatePassword,Profile,CreateUser, ProgressReport } from "./pages";

function App() {
  const location = useLocation();
  const {siteLoading} = useSelector((state) => state.site);
  
  return (
    <>
    {siteLoading && <PageLoading/>}
    <Routes location={location} key={location.pathname}>
      <Route element={<AuthLayout/>}>
          <Route path="/login" element={<Login/>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:action" element={<ResetPassword />} />
      </Route>
      <Route element={<MainLayout/>}>
        <Route path="/" element={<Home/>} />
        <Route path='/profile' element={<Profile/>} />
        <Route path='/users' element={<Users/>} />
        {/* <Route path="/user-list" element={<UserList/>} /> */}
        <Route path="/user-add" element={<CreateUser/>} />
        <Route path="/reports/user-feedbacks" element={<UserFeedback/>} />
        <Route path="/reports/user-session-rights" element={<UserSessionRight/>} />
        <Route path="/edit-profile" element={<EditProfile/>} />
        <Route path="/edit-user/:id" element={<UpdateUser/>} />
        <Route path="/kurum/update-password" element={<UpdatePassword/>} />
        <Route path="/progress-report/:userid" element={<ProgressReport/>} />
      </Route>
    </Routes>
    </>
      
  );
}

export default App;
