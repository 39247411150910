import React, { useEffect } from "react";
import { GetParticipantRemainingSeansHaklari } from "../../api/ParticipantRemainingSeansHaklari";
import { useState } from "react";
import { Table } from "reactstrap";
import { CircularProgress } from "@mui/material";


function ReducedUserSessionCount() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getParticipantRemainingSeansHaklari();
  }, []);
  const getParticipantRemainingSeansHaklari = async () => {
    setLoading(true);
    await GetParticipantRemainingSeansHaklari()
      .then((res) => {
        res.data = res.data.filter((item) => item.kalanhak <= 3);
        res.data.sort((a, b) => {
          return b.kalanhak - a.kalanhak;
        });
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className=" p-2" style={{ height: "400px", overflow: "scroll" }}>
      <h5 className="text-center">Seans Hakkı Azalan Kullanıcılar</h5>
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "400px" }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && data.length > 0 && (
        <Table className="table table-hover table-sm table-bordered mt-3">
          <thead>
            <tr>
              <th>Ad Soyad</th>
              <th>Kalan Seans Hak</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={`seans-feedback-${index}`}>
                <td>{item.kullaniciadsoyad}</td>
                <td>{item.kalanhak}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default ReducedUserSessionCount;
