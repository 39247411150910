import React from "react";
import { Card, CardActions, CardContent, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GetTotalCountRemainingSessions } from "../../api/TotalCountRemainingSessions";
import { setTotalSessionLeft } from "../../stores/userSlice";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

function TotalSessionLeft() {
  const { totalSessionLeft } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (totalSessionLeft == null) {
      getTotalSessionLeft();
    }
  }, [totalSessionLeft]);

  const getTotalSessionLeft = async () => {
    GetTotalCountRemainingSessions().then((res) => {
        dispatch(setTotalSessionLeft(res[0].kalanseans));
    });
  };

  return (
    <Card className="homeCard">
      <CardContent>
        <Typography
          padding={1}
          component="div"
          style={{
            background: "#ea484d",
            color: "#fff",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <span>Toplam Kalan Seans Sayısı</span>
        </Typography>
        <Typography
          variant="h5"
          component="div"
          marginTop={2}
          textAlign="center"
        >
          {totalSessionLeft == null ? <CircularProgress/> : totalSessionLeft}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={{
            color: "#9b9aba",
            fontSize: "12px",
            fontWeight: "400",
            margin: "auto",
          }}
        >
          <span className="homeCardText">Kalan Seans Hakkiniz</span>
        </Button>
      </CardActions>
    </Card>
  );
}

export default TotalSessionLeft;
