import { useState, useEffect, useMemo } from "react";
import {
  Button,
  Grid,
  Pagination,
  FormControlLabel,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Form, Label, Input, Col, Row, Table } from "reactstrap";
import { GetParticipantSeansFeedbacklist } from "../../api/ParticipantSeansFeedbacklist";
import { IconButton } from "@mui/material";
import { MdSearch } from "react-icons/md";
import { axiosInstance } from "../../api/axios";
import Moment from "moment";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function UserFeedBackTable() {
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );
  const { user } = useSelector((state) => state.user);
  const [userId, setUserId] = useState("");
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const date = useMemo(() => new Date(), []);

  useEffect(() => {
    setStartDate(user.anlasmabastar);
    getUser();
  }, [user]);

  useEffect(() => {
    if (startDate) getUserSeansFeedback(page);
  }, [page, startDate, searchText, showDeletedUsers]);

  const getUser = () => {
    axiosInstance
      .get("/participant?search=&showDeleted=false")
      .then((res) => {

   
        setUsers(res.data.data);
   
      })
      .catch((err) => {
        alert(err.request.response);
      });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "startDate") {
      setStartDate(value);
    } else if (name === "endDate") {
      setEndDate(value);
    }
  };

  const getUserSeansFeedback = async (page = 1) => {
    setLoading(true);
    console.log(userId);
    const res = await GetParticipantSeansFeedbacklist(
      startDate,
      endDate,
      userId,
      page,
      searchText,
      showDeletedUsers
    )
      .then((res) => {
        setData(res.data);
        setPagination(res.last_page);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //! Excel Download KULLANICI LISTESI
  const createWorkbookFromData = (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Başlık satırını ekleyin ve hücrelere stil uygula
    const titleRow = worksheet.addRow([
      "Ad Soyad",
      "Seviye",
      "Tarih",
      "Speaker",
      "Kalan Seans Sayısı",
      "Feedback",
    ]);
    titleRow.eachCell((cell) => {
      cell.font = { color: { argb: "FFFFFF" }, bold: true, size: 14 };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "dc3545" }, // Kırmızı arka plan rengi
      };
      cell.alignment = { horizontal: "center" }; // Metni ortala
    });

    // Her kullanıcı için bir satır ekleyin
    data.forEach((user) => {
      worksheet.addRow([
        user.kullaniciadsoyad,
        user.seviye,
        user.tarih,
        user.speaker,
        user.KalanSeans,
        user.feedback,
      ]);
    });

    worksheet.columns.forEach((column) => {
      column.width = 25;
    });

    return workbook;
  };

  //! Excel Download KULLANICI LISTESI
  const handleDownloadExcel = async () => {
    try {
      const workbook = createWorkbookFromData(data);
      const excelBuffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "KullaniciListesi.xlsx");
      Swal.fire("Başarılı!", "Excel dosyası başarıyla indirildi!", "success");
    } catch (error) {
      console.error("Excel indirme hatası:", error);
      Swal.fire("Hata!", "Excel dosyası indirilemedi.", "error");
    }
  };

  //! PDF Download KULLANICI LISTESI
  const createPdfFromData = (data) => {
    const documentDefinition = {
      content: [
        {
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto", "auto", "auto"],
            body: [
              [
                { text: "Ad Soyad", style: "tableHeader" },
                { text: "Seviye", style: "tableHeader" },
                { text: "Tarih", style: "tableHeader" },
                { text: "Speaker", style: "tableHeader" },
                { text: "Kalan Seans Sayısı", style: "tableHeader" },
                { text: "Feedback", style: "tableHeader" },
              ],
              ...data.map((user) => [
                user.kullaniciadsoyad,
                user.seviye,
                user.tarih,
                user.speaker,
                user.KalanSeans,
                user.feedback,
              ]),
            ],
          },
        },
      ],
      styles: {
        tableHeader: {
          bold: true,
          fillColor: "#DC3545",
          color: "#FFFFFF",
        },
      },
    };

    return documentDefinition;
  };

  //! PDF Download KULLANICI LISTESI
  const handleDownloadPDF = async () => {
    try {
      // Verileri kullanarak bir PDF belgesi oluşturun
      const documentDefinition = createPdfFromData(data);
      // PDF dosyasını oluştur
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      // PDF dosyasını indir
      pdfDocGenerator.download("KullaniciListesi.pdf");
      // İndirme başladığında popup göster
      Swal.fire("Başarılı!", "PDF dosyası başarıyla indirildi!", "success");
    } catch (error) {
      console.error("PDF indirme hatası:", error);
      // Hata durumunda hata mesajını göster
      Swal.fire("Hata!", "PDF dosyası indirilemedi.", "error");
    }
  };

  return (
    <div className="border rounded p-3">
      <Button
        onClick={handleDownloadExcel}
        className="text-white"
        variant="outlined"
        color="error"
        style={{
          background: "#dc3545",
        }}
      >
        EXCEL İNDİR
      </Button>
      <Button
        onClick={handleDownloadPDF}
        className="text-white"
        variant="outlined"
        color="error"
        style={{
          background: "#dc3545",
          marginLeft: "10px",
        }}
      >
        PDF İNDİR
      </Button>

      <Form
        style={{ marginTop: "10px" }}
        onSubmit={(e) => {
          e.preventDefault();
          getUserSeansFeedback();
        }}
      >
        <Row className="g-3">
          <Col sm={12} md={3} style={{ marginTop: "0px" }}>
            <Label className="visually-hidden" for="exampleSelect">
              Kullanici Seciniz
            </Label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={users}
              getOptionLabel={(option) => option.kullaniciadsoyad}
              renderInput={(params) => (
                <TextField {...params} label="Tüm Liste" margin="normal" />
              )}
              onChange={(e, value) => {
                setUserId(`${value?.id}`);
              }}
            />
          </Col>
          <Col sm={12} md={3}>
            <Input
              name="startDate"
              type="date"
              value={startDate}
              onChange={handleChange}
            />
          </Col>
          <Col sm={12} md={3}>
            <Input
              name="endDate"
              type="date"
              value={endDate}
              onChange={handleChange}
            />
          </Col>
          <Col sm={12} md={3} className="d-flex flex-row gap-3">
            <IconButton
              className="searchBtn"
              size="small"
              type="submit"
              variant="contained"
              style={{
                marginLeft: "10px",
                width: "30px",
                height: "30px",
                background: "#ea484d",
              }}
            >
              <MdSearch size={25} className="searchIcon" color="#fff" />
            </IconButton>
            <FormControlLabel
              style={{
                height: "fit-content",
              }}
              onChange={(e) => {
                setShowDeletedUsers(e.target.checked);
              }}
              control={<Checkbox />}
              label="Silinmiş Kullanıcıları Göster"
            />
          </Col>
        </Row>
      </Form>

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid xs={12} sm={12} md={12}>
          <div className="d-flex justify-content-end">
            <TextField
              className="my-3"
              id="outlined-basic"
              label="Arama"
              defaultValue=""
              type="search"
              variant="outlined"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          {loading && (
            <div
              style={{
                minHeight: "600px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && data.length === 0 && (
            <div className="text-center mt-3">Kayıt Bulunamadı</div>
          )}

          {!loading && data.length !== 0 && (
            <>
              <Table className="table table-hover table-sm table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Ad Soyad</th>
                    <th>Seviye</th>
                    <th>Tarih</th>
                    <th>Speaker</th>
                    <th>Kalan Seans Sayısı</th>
                    <th>Feedback</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={`seans-feedback-${index}`}>
                      <td>{item.kullaniciadsoyad}</td>
                      <td>{item.seviye}</td>
                      <td>{Moment(item.tarih).format("DD.MM.YYYY")}</td>
                      <td>{item.speaker}</td>
                      <td>{item.KalanSeans}</td>
                      <td>{item.feedback}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  onChange={(e, p) => setPage(p)}
                  showFirstButton
                  showLastButton
                  page={page}
                  count={pagination}
                  variant="outlined"
                  color="primary"
                ></Pagination>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default UserFeedBackTable;
