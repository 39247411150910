import { Button, InputAdornment, Stack, TextField, Card } from "@mui/material";
import { AccountCircle, MailOutlined, Numbers } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import styles from "./update-user.module.css";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../api/axios";
import { useDispatch } from "react-redux";
import { setSiteLoading } from "../../stores/siteSlice";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import {
  setTotalSessionLeft,
  setTotalUsedSessionRight,
} from "../../stores/userSlice";

function UpdateUser() {
  let { id } = useParams();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    shouldUnregister: false,
  });
  const navigate = useNavigate();
  const [autoRenew, setAutoRenew] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    console.log(data);
    console.log(data.auto_renew_count);
    dispatch(setSiteLoading(true));

    //! Özel doğrulama
    const phoneNumber = data.phone;
    console.log(phoneNumber);

    data.id = +id;
    data.seansNumber = +data.seansNumber;
    data.auto_renew_count = parseInt(data.auto_renew_count, 10); //! Çevirme işlemi eklenmiş
    data.auto_renew_day = parseInt(data.auto_renew_day, 10); //! Çevirme işlemi eklenmiş
    data.status = parseInt(data.status, 10); //! Çevirme işlemi eklenmiş
    axiosInstance
      .put(`/participant`, data)
      .then((res) => {
        if (phoneNumber.length < 11) {
          dispatch(setSiteLoading(false));
          Swal.fire(
            "Hata!",
            "Telefon numarası eksik, lütfen tam bir numara girin.",
            "error"
          );
          return;
        } else {
          Swal.fire("Başarılı!", "Kullanıcı başarıyla güncellendi.", "success");
          dispatch(setTotalSessionLeft(null));
          dispatch(setTotalUsedSessionRight(null));
          navigate("/users");
        }
      })
      .catch((error) => {
        Swal.fire("Hata!", error.response.data.message, "error");
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  };

  useEffect(() => {
    GetParticipantId(id);
  }, []);

  function GetParticipantId(id) {
    dispatch(setSiteLoading(true));
    axiosInstance
      .get(`/participant/${id}`)
      .then((res) => {
        setAutoRenew(res.data.data[0].auto_update);
        setValue("name", res.data.data[0].name);
        setValue("lastname", res.data.data[0].lastname);
        setValue("email", res.data.data[0].email);
        setValue("phone", res.data.data[0].phone);
        setValue("status", res.data.data[0].status);
        setValue("seansNumber", res.data.data[0].kalanhak);
        setValue("endDate", res.data.data[0].BitTar.split("T")[0]);
        setValue("auto_renew", res.data.data[0].auto_update);
        setValue(
          "auto_renew_day",
          parseInt(res.data.data[0].auto_refresh_day, 10)
        );
        setValue(
          "auto_renew_count",
          parseInt(res.data.data[0].auto_refresh_count, 10)
        );
      })
      .finally(() => {
        dispatch(setSiteLoading(false));
      });
  }

  return (
    <div className="shadow px-2 py-5 rounded">
      <h5>Kullanıcı Düzenleme</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid spacing={3} container>
          <Grid xs={12} sm={12} md={6} item>
            <Card className={styles.card}>
              <Stack>
                <TextField
                  required="true"
                  name="name"
                  {...register("name", { required: "İsim alanı zorunludur." })}
                  id="name"
                  label="İsim"
                  margin="normal"
                  size="small"
                  InputProps={{
                    className: styles.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: styles.input,
                  }}
                />

                {errors.name?.message && (
                  <p className="error-message">{errors.name?.message}</p>
                )}

                <TextField
                  required="true"
                  name="lastname"
                  {...register("lastname", {
                    required: "Soyisim alanı zorunludur.",
                  })}
                  label="Soyisim"
                  margin="normal"
                  size="small"
                  InputProps={{
                    className: styles.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: styles.input,
                  }}
                />

                {errors.lastname?.message && (
                  <p className="error-message">{errors.lastname?.message}</p>
                )}

                <TextField
                  required="true"
                  type="email"
                  autoComplete="false"
                  name="email"
                  {...register("email", {
                    required: "Email alanı zorunludur.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: `Geçerli bir email adresi giriniz!`,
                    },
                  })}
                  label="Email"
                  margin="normal"
                  size="small"
                  InputProps={{
                    className: styles.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlined />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: styles.input,
                  }}
                />

                {errors.email?.message && (
                  <p className="error-message">{errors.email?.message}</p>
                )}

                <p className="mb-0 pb-0">Telefon Numarası *</p>
                <Controller
                  name={"phone"}
                  control={control}
                  render={(props) => {
                    return (
                      <PhoneInput
                        onChange={(e) => {
                          props.field.onChange(e);
                        }}
                        autocompleteSearch={true}
                        enableSearch={true}
                        inputProps={{
                          id: "phone",
                          name: "phone",
                          required: true,
                          autoComplete: "none",
                          "data-testid": "input-id",
                        }}
                        alwaysDefaultMask={true}
                        defaultMask={"(...) ...-..-.."}
                        inputClass={`custom-phone-input w-100`}
                        country={"tr"}
                        value={props.field.value}
                      />
                    );
                  }}
                />
              </Stack>
            </Card>
          </Grid>

          <Grid xs={12} sm={12} md={6} item>
            <Card className={styles.card}>
              <Stack>
                <TextField
                  name="seansType"
                  disabled
                  id="outlined-disabled"
                  label="Seans Türü"
                  margin="normal"
                  size="small"
                  defaultValue="BireBir 30dk"
                />

                <TextField
                  required="true"
                  name="seansNumber"
                  label="Aktif Seans Sayısı"
                  {...register("seansNumber", {
                    required: "Seans sayısı alanı zorunludur.",
                  })}
                  margin="normal"
                  size="small"
                  InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Numbers />
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.seansNumber?.message && (
                  <p className="error-message">{errors.seansNumber?.message}</p>
                )}

                <p className="mb-0 pb-0"> Otomatik Yenileme</p>

                <select
                  required="true"
                  name="auto_renew"
                  size="small"
                  {...register("auto_renew")}
                  margin="normal"
                  labelId="auto-renew-label"
                  id="auto-renew-select"
                  label="Otomatik Yenileme"
                  className="w-100 form-control"
                  onChange={(e) => setAutoRenew(e.target.value)}
                >
                  <option value={0}>Pasif</option>
                  <option value={1}>Aktif</option>
                </select>

                {autoRenew == 0 && (
                  <TextField
                    name="endDate"
                    type="date"
                    {...register("endDate", {
                      required: "Seans bitiş tarihi alanı zorunludur.",
                    })}
                    label="Seans Bitiş Tarihi"
                    size="small"
                    margin="normal"
                    required="true"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}

                {autoRenew == 0 && errors.endDate?.message && (
                  <p className="error-message">{errors.endDate?.message}</p>
                )}

                {autoRenew == 1 && (
                  <>
                    <p className="mb-0 pb-0">Yenileneceği Gün</p>
                    <select
                      required="true"
                      name="auto_renew_day"
                      size="small"
                      {...register("auto_renew_day")}
                      margin="normal"
                      labelId="auto-renew-day-label"
                      id="auto_renew_day_select"
                      className="form-control w-100"
                    >
                      {/* make loop zero to 30 */}
                      {Array.from(Array(31).keys()).map((item, index) => (
                        <option key={index} value={item + 1}>
                          Ayın {item + 1}.günü
                        </option>
                      ))}
                    </select>
                    <p className="mb-0 pb-0">Yenilenecek Seans Sayısı</p>
                    <input
                      type="number"
                      name="auto_renew_count"
                      {...register("auto_renew_count")}
                      className="form-control w-100"
                    />
                  </>
                )}
                <p className="mb-0 pb-0">Hangi Seviyesiniz *</p>
                <select
                  required="true"
                  name="status"
                  size="small"
                  ref={register}
                  {...register("status", {
                    required: "Seviye alanı zorunludur.",
                  })}
                  margin="normal"
                  labelId="level-lable"
                  id="level-select"
                  className={"form-control w-100 mb-2"}
                >
                  <option value={1}>We Need To Talk (Başlangıç)</option>
                  <option value={2}>I Believe I Can Fly (Orta)</option>
                  <option value={3}>That's My Thing (İleri)</option>
                </select>

                {errors.status?.message && (
                  <p className="error-message">{errors.status?.message}</p>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  className={styles.button}
                >
                  Kullanıcıyı Güncelle
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default UpdateUser;
