import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    user:undefined,
    totalUsersCount:null,
    totalSessionLeft:null,
    lastDayOfUse:null,
    totalUsedSessionRight:null,
};

const userSlice = createSlice({
    name: "user",
    initialState: initalState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setTotalUsersCount(state, action) {
            state.totalUsersCount = action.payload;
        },
        setTotalSessionLeft(state, action) {
            state.totalSessionLeft = action.payload;
        },
        setLastDayOfUse(state, action) {
            state.lastDayOfUse = action.payload;
        },
        setTotalUsedSessionRight(state, action) {
            state.totalUsedSessionRight = action.payload;
        }
    }
});

export const {setUser,setTotalUsersCount,setTotalSessionLeft,setLastDayOfUse,setTotalUsedSessionRight} = userSlice.actions;

export default userSlice;