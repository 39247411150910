import React from "react";
import logo from "../assets/logo/log.png";
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";
import styles from "./auth-layout.module.css";
function AuthLayout() {
  return (
    <div className={styles.auth__row}>
      <main>
        <div className={styles.auth__row__row}>
          <div className={styles.auth__colm__logo}>
            <img src={logo} alt="BukyTalk_logo"></img>
          </div>
          <div className={styles.colm__form}>
            <Outlet />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AuthLayout;
