import React from "react";
import { Card, CardActions, CardContent, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { GetLastDayOfUse } from "../../api/LastDayOfUse";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { setLastDayOfUse } from "../../stores/userSlice";
import CircularProgress from "@mui/material/CircularProgress";

function LastDayOfUse() {
  const { lastDayOfUse } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (lastDayOfUse == null) {
        getLastDayOfUse();
    }
    }, [lastDayOfUse]);

    const getLastDayOfUse = async () => {
        GetLastDayOfUse().then((res) => {
            dispatch(setLastDayOfUse(res[0].sonkullanimtarihi));
        });
    };

  return (
    <Card className="homeCard">
      <CardContent>
        <Typography
          padding={1}
          component="div"
          style={{
            background: "#ea484d",
            color: "#fff",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <span>Son Kullanım Tarihi</span>
        </Typography>
        <Typography
          variant="h5"
          component="div"
          marginTop={2}
          textAlign="center"
        >
          {lastDayOfUse == null ? <CircularProgress/> : Moment(lastDayOfUse).format("DD.MM.YYYY")}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={{
            color: "#9b9aba",
            fontSize: "12px",
            fontWeight: "400",
            margin: "auto",
          }}
        >
          <span className="homeCardText">Tarihinde Sonlanacak</span>
        </Button>
      </CardActions>
    </Card>
  );
}

export default LastDayOfUse;
