import Button from "@mui/material/Button";
import alertify from "alertifyjs";
import { AiFillDelete, AiFillSetting } from "react-icons/ai";
import { GiLevelEndFlag } from "react-icons/gi";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../api/axios";


export const DeleteButton = (params) => {
  return (
    <Button
      id="btnDelete"
      onClick={() => {
        alertify.confirm(
          "Sil",
          "Kullanıcıyı silmek istediğinize emin misiniz?",
          function () {
            axiosInstance
              .delete(`/participant/${params.id}`)
              .then(() => {
                alertify.set("notifier", "position", "top-right");
                alertify.success("Silme İşlemi Başarılı.");
                setTimeout(() => {
                  window.location.reload();
                },500);
              })
          },
          function () {
            alertify.set("notifier", "position", "top-right");
            alertify.error("Silme İşlemi İptal Edildi.");
          }
        );
      }}
    >
      <AiFillDelete />
    </Button>
  );
};

export const CountButton = (params) => {
  return (
    <Link to={`/edit-user/${params.id}`}>
      <Button id="btnUpdate">
        <AiFillSetting />
      </Button>
    </Link>
  );
};

export const GelisimRaporuButton = (params) => {

  return (
    <Link to={`/progress-report/${params.id}`}>

      <Button id="btnUpdate">

        <GiLevelEndFlag />

      </Button>

    </Link>

  );

};

export const columns = [
  {
    field: "kullaniciadsoyad",
    headerName: "Ad Soyad",
    width: 230,
    editable: false,
    onclick: (params) => <Navigate to={`/edit-user/${params.id}`} />,
    //renderCell: () => <CountButton />,
  },
  {
    field: "status",
    headerName: "Seviye",
    width: 120,
    editable: false,
    onclick: (params) => <Navigate to={`/edit-user/${params.id}`} />,
    valueGetter: (params) => {
      if (params.row.status == 1) {
        return "Başlangıç";
      } else if (params.row.status == 2) {
        return "Orta";
      } else if (params.row.status == 3) {
        return "İleri";
      }
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 240,
    sort: true,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Telefon",
    width: 150,
    editable: false,
    sort: true,
  },
  {
    field: "kalanhak",
    headerName: "Kalan Seans Hak",
    type: "number",
    width: 130,
    editable: false,
    sort: true,
  },
  {
    field: "Gelişim Raporu",
    headerName: "Gelişim Raporu",
    width: 130,
    editable: false,
    filterable: false,
    sort: false,
    disableExport: true,
    renderCell: GelisimRaporuButton,
  },
  {
    field: "Düzenle",
    headerName: "Düzenle",
    width: 100,
    editable: false,
    filterable: false,
    sort: false,
    disableExport: true,
    renderCell: CountButton,
  },
  {
    field: "Sil",
    headerName: "Sil",
    width: 100,
    editable: false,
    filterable: false,
    sort: false,
    disableExport: true,
    renderCell: DeleteButton,
  },
];